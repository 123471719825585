
import Vue from 'vue'

export default Vue.extend({
  props: {
    href: {
      type: String,
      default: null
    },
    to: {
      type: String,
      default: null
    },
    classes: {
      type: String,
      default: 'button'
    }
  },
  computed: {
    type () {
      if (this.href) {
        return 'a'
      } else {
        return 'button'
      }
    }
  }
})
